<template>
  <div class="science-policy-detail">
    <div class="spd-root">
      <div class="spd-main">
        <div class="spd-title ellipse-1">
          {{detailInfo&&detailInfo.title}}
        </div>
        <div style="background:#EBF2FA;color:#859BBD;width:100%;padding:10px;text-align:center;margin-top:20px">
          <div>
            <span>政策来源：{{detailInfo&&detailInfo.policySource}}</span>
            <span style="margin-left: 30px">地区分类：{{detailInfo&&detailInfo.provinceCode}}{{detailInfo&&detailInfo.cityCode}}{{detailInfo&&detailInfo.areaCode}}</span>
            <span style="margin-left: 30px">添加时间：{{detailInfo&&detailInfo.createdTime}}</span>
          </div>
          <div style="margin-top:14px">
            标签：
            <span class="spd-bottom-tag-circle" v-for="(item,index) in labelList" :key="index">{{item}}</span>
          </div>
        </div>
        <div class="spd-body" v-html="detailInfo&&detailInfo.content"></div>
        <!-- <div class="spd-bottom">
          <div class="spd-bottom-tag"  style="position: relative">
            <div class="spd-bottom-tag-name">标签：</div>
            <div class="spd-bottom-tag-circle" v-for="(item,index) in labelList" :key="index">{{item}}</div>
            <div style="position: absolute;right: 0;cursor: pointer"
                 v-if="detailInfo.articleAttachments && detailInfo.articleAttachments[0].url">
            <span v-for="(item,index) in detailInfo.articleAttachments" :key="index" @click="downloadFile(item.url)"
                  style="padding: 0 10px">
                         <img :src="download" alt="" style="vertical-align: text-top"><span
                style="text-decoration: none;color: black">附件{{ index + 1 }}</span>

            </span>
            </div>
          </div>
          <div class="spd-bottom-click">
            <div class="ellipse-1" @click="getDetail(preDetailInfo&&preDetailInfo.id)">
              上一篇：{{preDetailInfo&&preDetailInfo.title}}
            </div>
            <div class="ellipse-1" @click="getDetail(nextDetailInfo&&nextDetailInfo.id)">
              下一篇：{{nextDetailInfo&&nextDetailInfo.title}}
            </div>
          </div>
        </div> -->
      </div>

      <div class="spd-right">
        <patent-component/>
      </div>
    </div>

  </div>
</template>

<script>

  import newestPatentAndAchievement from "./newestPatentAndAchievement";
  import {getSciencePolicyDetailById} from "../../plugins/api/sciencePolicyApi";
  import download from '@/assets/image/index/download.png'
  import axios from "axios";
  import {patentUrl} from "../../plugins/http/baseUrl";
  import {getLocalToken} from "../../plugins/tools/storage";
  export default {
    name: "sciencePolicyDetail",
    data() {
      return {
        download:download,
        detailInfo: {},
        preDetailInfo: {},
        nextDetailInfo: {},
        labelList: []
      }
    },
    components: {
      patentComponent: newestPatentAndAchievement,
    },
    mounted() {
      this.getDetail(this.$route.query.id);
    },
    methods: {
      async downloadFile(url){
        let fileName=url;
        var x = new XMLHttpRequest();
        x.open("GET", url, true);
        x.responseType = 'blob';
        x.onload=function(e) {
          //会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
          var url = window.URL.createObjectURL(x.response)
          var a = document.createElement('a');
          a.href = url
          a.download = fileName;
          a.click()
        }
        x.send();
      },
      async getDetail(id) {
        const params = {
          id: id
        }
        const json = await getSciencePolicyDetailById(params);
        if (json && json.code === 0 && json.result) {
          if (json.result.nowArticleVo) {
            scrollTo(0, 0)
            this.detailInfo = json.result.nowArticleVo;
            this.labelList = json.result.nowArticleVo.label.split(';');
          }
          if (json.result.preArticleVo) {
            this.preDetailInfo = json.result.preArticleVo;
          }
          if (json.result.nextArticleVo) {
            this.nextDetailInfo = json.result.nextArticleVo
          }
        }
      }
    },

  }
</script>

<style scoped lang="css">
  .science-policy-detail {
    background-color: #FAFAFA;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
  }

  .spd-root {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }

  .spd-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    padding: 30px 40px;
    width: 840px;
    height: fit-content;
  }

  .spd-title {
    font-size: 18px;
    font-family: Microsoft YaHei, serif;
    font-weight: bold;
    color: #333333;
  }

  .spd-str {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  .spd-str span {
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;

  }

  .spd-time {
    font-size: 12px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #666666;
    margin-top: 15px;
    width: 100%;
  }

  .spd-body {
    margin-top: 15px;
  }

  .spd-body >>> img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    height: auto !important;
  }

  .spd-bottom {
    border-top: 1px #E8E8E8 solid;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .spd-bottom-tag {
    display: flex;
    flex-direction: row;
    padding-top: 35px;
    flex-wrap: wrap;
    align-items: center;
  }

  .spd-bottom-tag-name {
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #666666;
  }

  .spd-bottom-tag-circle {
    padding: 5px;
    border: 1px solid #DCDCDC;
    margin-left: 10px;
    background: #fff;
  }

  .spd-bottom-click {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 40px;
  }

  .spd-bottom-click div {
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
    flex: 0.45;
    cursor: pointer;
  }

  .spd-right {
    margin-left: 30px;
    height: fit-content;
  }
</style>
